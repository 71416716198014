<template>
  <div class="page page-xranking-all-cvs">
    <PageHeader
      :icon="$t('CV.ICON')"
      :title="$t('CV.TITLES')"
      class="pt-2"
    >
      <template>
            <v-btn
              small
              depressed
              color="success"
              class="page-xranking-all-cvs__button-upload ml-2"
              @click="searchByCV"
            >
              <v-icon>mdi-plus</v-icon>
              {{ $t("COMMON.ADD_CVS") }}
            </v-btn>
      </template>
      <template slot="nav">
        <div>
          <small><strong>{{ $tc('CV.TITLE_COUNT', cvs.length) }}</strong></small>
        </div>
      </template>
    </PageHeader>

    <List
      v-if="(noData === false && loading === true) || cvs.length"
      checkboxClass="page-xranking-all-cvs__checkbox"
      :items="currentPageData"
      :count="cvs.length"
      :offset="offset"
      :limit="limit"
      :selected="selected"
      :pagination="true"
      :loading="loading"
      @page="changePage"
      @reload="validateRaOrg"
      @click="goToItem"
      @select="selectItems"
    >
      <template v-slot:header>
        <div class="page-xranking-all-cvs__header-list col-4">
          <v-text-field
            ref="where.title"
            hide-details
            outlined
            dense
            clearable
            prepend-inner-icon="mdi-magnify"
            :placeholder="$t('COMMON.SEARCH')"
            @keyup="searchItems"
            @click:clear="searchItems"
            @select="selectItems"
          />
        </div>
      </template>

      <template v-slot:selections>
        <v-col cols="12" class="pt-0 pl-0">
          <div><small><strong>{{ $tc('COMMON.SELECTED', +selected.length) }}</strong></small></div>
          <v-btn-toggle dark rounded>
            <v-btn
              small
              rounded
              depressed
              color="error"
              @click="toogleDialogRemove(true)"
            >
              <v-icon size="20" class="mr-2">mdi-delete</v-icon>
              {{ $t('COMMON.REMOVE') }}
            </v-btn>
            <v-btn
              small
              rounded
              depressed
              color="secondary custom black--text"
              @click="selectItems([])"
            >
              <v-icon size="20" class="mr-2" color="black">mdi-close</v-icon>
              {{ $t('COMMON.CANCEL') }}
            </v-btn>
          </v-btn-toggle>
        </v-col>
      </template>

      <template v-slot:item-list="item">
        <v-list-item-content>
          <v-list-item-title class="primary--text">
            <strong>{{fullname(item)}}</strong>
          </v-list-item-title>

          <v-list-item-subtitle>
            <v-chip
              v-if="item.identities.data.city.ref.value"
              x-small
              label
              class="pl-1 ml-1"
            >
              <v-icon class="mr-1" size="14">mdi-map-marker</v-icon>
               {{ item.identities.data.city.ref.value + ", " + item.identities.data.city.ref.state + ", " + item.identities.data.city.ref.postal_code }}
            </v-chip>
          </v-list-item-subtitle>
        </v-list-item-content>
        
        <v-list-item-action class="ml-3 date">
          {{ $moment(item.created_at).fromNow() }}
        </v-list-item-action>

        <v-list-item-action>
          <div>
            <v-tooltip bottom transition="none">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  depressed
                  class="page-xranking-all-cvs__actions-match px-1"
                  :min-width="36"
                  v-bind="attrs"
                  v-on="on"
                  icon
                  @click.stop="goToMatching(item)"
                >
                  <v-icon color="accent">mdi-magnify-scan</v-icon>
                </v-btn>
              </template>
               {{ $t('COMMON.TOOLTIP.MATCHING') }}
            </v-tooltip>
            <v-tooltip bottom transition="none">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  icon
                  v-bind="attrs"
                  v-on="on"
                  class="ml-2"
                  @click.stop="getRatedCV(item.id)"
                >
                  <v-icon>mdi-thumb-up-outline</v-icon>
                </v-btn>
              </template>
              {{ $t('COMMON.TOOLTIP.RATING') }}
            </v-tooltip>
            <v-tooltip bottom transition="none">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  icon
                  v-bind="attrs"
                  v-on="on"
                  class="ml-2"
                  @click.stop="loadFileDetails(item.id)"
                >
                  <v-icon>mdi-download-outline</v-icon>
                </v-btn>
              </template>
              {{ $t('COMMON.TOOLTIP.DOWNLOAD') }}
            </v-tooltip>
            <v-tooltip bottom transition="none">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  icon
                  v-bind="attrs"
                  v-on="on"
                  class="page-xranking-all-cvs__actions-delete ml-2"
                  @click.stop="getRemoveCV(item)"
                >
                  <v-icon>mdi-delete-outline</v-icon>
                </v-btn>
              </template>
              {{ $t('COMMON.TOOLTIP.DELETE') }}: {{ `${fullname(item)} ` }} {{$t('CV.TITLE')}}
            </v-tooltip>
          </div>
        </v-list-item-action>
      </template>
    </List>

    <div
      v-if="!cvs.length && noData"
      class="page-xranking-all-cvs__no-data  mt-10"
    >
      {{ $t("COMMON.NO_DATA") }}
    </div>
    <DialogRemove
      :value="showDialogRemove"
      :items="selected"
      item-text="id"
      @confirm="confirmRemoveItems"
      @close="toogleDialogRemove(false)"
      @select="selectItems"
    />

    <RateDialog
      v-if="showRateDialog"
      @closeDialog="toogleRateDialog"
      class="rate-dialog"
    />
  </div>
</template>

<script>
import { mapState, mapActions, mapGetters } from "vuex"
import PageHeader from '@/components/PageHeader'
import List from '@/components/List'
import DialogRemove from '@/components/DialogRemove'
import RateDialog from '@/components/RateDialog'

export default {
  name: "PageXrankingAllCvs",
  components: {
    PageHeader,
    List,
    DialogRemove,
    RateDialog,
  },
  data: (_this) => ({
    selected: [],
    loading: false,
    noData: false,
    offset: 0,
    limit: 10,
    page: 1,
    showDialogRemove: false,
    showRateDialog: false,
  }),
  computed: {
    ...mapState('user', {
      userOfficeId: state => state.user.office_id,
    }),

    ...mapGetters({
      token: 'user/getToken',
      cv: 'cv/getCV',
      cvs: 'cv/getCVS',
    }),

    currentPageData() {
      const start = (this.page - 1) * this.limit
      const end = start + this.limit
      const cloneItems = JSON.parse(JSON.stringify(this.cvs))

      return cloneItems.slice(start, end)
    },
  },

  mounted() {
    this.validateRaOrg()
  },

  methods: {
    ...mapActions({
      addCVMatch: 'cv/addCVMatch',
      addCV: 'cv/addCV',
      addCVS: 'cv/addCVS',
    }),

    goToItem (item) {
      item = item ? item : this.selected[0]
      const route = `/xranking/cvs/${item.id}`
      this.$router.push(`/xranking/cvs/${item.id}`)
    },

    selectItem (item) {
      for (let i=0, len = this.selected.length; i < len; i++) {
        if (this.selected[i].id === item.id) return this.selected.splice(i, 1)
      }

      return this.selected.push(item)
    },

    selectItems (arr) {
      this.selected = arr
    },

    changePage (page) {
      this.page = page
      this.offset = this.page - 1
      this.searchItems()
    },

    validateRaOrg() {
      this.loading = true
      this.$services.xusers_ra.organization.describe()
        .then((response) => {
          if (response.data.organization.id !== this.userOfficeId) {
            this.addCVS([])
            this.noData = true

            this.$store.dispatch('messenger/add', {type: 'warning', code: 'XRANKING.MISSING_OFFICE_ORG_MAPPING',
              message: `Office ${this.userOfficeId} does not have a corresponding xusers organization mapped`, time: 5000 })
          } else {
            this.searchItems()
          }
        })
        .catch(({ error }) => {
          this.addCVS([])
          this.noData = true

          let message = `Error validating organization for office ${this.userOfficeId}`
          if (error && error.code && error.message) {
            message = `${error.code} - ${error.message}`
          }
          this.$store.dispatch('messenger/add', {type: 'error', code: 'XRANKING.ORGANIZATION_ERROR',
            message, time: 5000 })
        })
    },

    searchItems() {
      const query_cvs = {
        page: 1,
        size: 1000,
        sort: '-date',
      }
    
      this.$services.xranking_ra.v2_cvs.get(query_cvs)
        .then(({ data: { data }}) => {
          this.addCVS(data || [])
        })
        .finally(() => {
          this.loading = false
          this.noData = true
        })

      this.offset = this.limit * this.page - 1
    },

    goToMatching (item) {
      const { id } = item ? item : this.selected[0]
      this.addCVMatch([])
      this.$router.push(`/xranking/matching/cvs/${id}`)
    },

    async loadFileDetails(fileId) {
      let data

      try{
        const { data: responseData } = await this.$services.xxlsfiles_ra.file.detailes(fileId)
        data = responseData
      } catch({ error: { message, details: { Error }}}) {
        const msg = `${message}. ${Error || ''}`
        this.$store.dispatch('messenger/add', {type: 'error', code: 'XRANKING.SERVER_ERROR',
          message: msg, time: 5000 })
      }

      if (data.status === 'processed') {
        const link = document.createElement('a')
        const downloadFileName = `${data.original_filename.split('.').shift()}.xlsx`
        const windowUrl = window.URL || window.webkitURL
        const req = new window.XMLHttpRequest()
        req.responseType = 'blob'
        req.open(
          'GET', `${process.env.VUE_APP_KONG_URL}/xranking/v2/cvs/${fileId}?exportFormat=xlsx&exportLang=${this.$t('COMMON.ANOTHER.LANG')}`
        )
        req.setRequestHeader('authorization', `Bearer ${this.token}`)
        req.onload = function onload() {
          const blob = req.response // Note: not oReq.responseText
          const url = windowUrl.createObjectURL(blob)
          link.setAttribute('href', url)
          link.setAttribute('download', downloadFileName)
          link.click()
          windowUrl.revokeObjectURL(url)
        }
        req.send()
      }
    },

    fullname({ identities }) {
      const { data: { firstname, lastname }} = identities
      return lastname.concat(firstname).replace(/\s/g, '').length ? `${lastname} ${firstname}`: this.$t('CV.TITLES_FIELDS.unknown')
    },

    getRemoveCV(item) {
      this.selectItem(item)
      this.toogleDialogRemove(true)
    },

    toogleDialogRemove(state) {
      this.showDialogRemove = state
    },

    async confirmRemoveItems() {
      const ids = this.selected.map(v => v.id)
  
      for (let i = 0; i < ids.length; i++) {
        try {
          this.loading = true
          const { data: { result }, status } = await this.$services.xrankingact_ra.cv.remove(ids[i])
          this.selected = this.selected.filter(item => item.id !== ids[i])
          this.loading = false
          this.$store.dispatch('messenger/add', {type: 'success', code: 'XRANKING.DELETE_CV_SUCCESSFULLY', message: result, time: 3000 })
          await this.searchItems()
        } catch({ message, code }) {
          this.loading = false
          this.$store.dispatch('messenger/add', {type: 'error', code, message, time: 5000 })
          await this.searchItems()
        }
      }
    },

    getRatedCV(cvId) {
      const cvs = this.cvs.filter(item => item.id === cvId)

      if (!cvs.length) return
      this.addCV(cvs[0])
      this.toogleRateDialog(true)
    },

    toogleRateDialog(state) {
      this.showRateDialog = state
    },

    searchByCV() {
      this.$router.push('/xranking/upload/cv')
    },
  },
}
</script>

<style lang="scss">
.page-xranking-all-cvs {
  position: relative;

  .v-toolbar__content {
    align-items: center;
  }
}

.page-xranking-all-cvs__button-upload {
  margin-top: 10px;
  border-radius: 10px;

  .v-btn__content {
    gap: 5px;
  }
}

.page-xranking-all-cvs__header-list {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.page-xranking-all-cvs__actions-delete.theme--light.v-btn.v-btn--icon  {
  color: $color-pink;

  &:hover {
    color: $color-pink-hover;
  }
}

.page-xranking-all-cvs__no-data {
  display: flex;
  justify-content: center;
  font-size: 24px;
  color: $color-dark-blue;
}
</style>
